import { Link } from '@/lib/router-events/patch-router/link';
import styles from '@/components/legacy/header/Header.styles';
import { useCallback } from 'react';
import useMap from '@/hooks/useMap';
import useAnalytics from '@/hooks/useAnalytics';
import { HEADER } from '@/utils/analyticsClientHelper';
import { getSimpleClickFact } from '@/analytics/helpers/cventAnalytics/getSimpleClickFact';
import type { SimpleClickFact } from '@/analytics/models/cventAnalytics/SimpleClickFact';

interface Props {
  title: string;
  address: string;
  isActive?: boolean;
  ariaLabel: string;
  clickEntity: string;
}
function getStyles(isActive = false): string {
  return isActive ? styles.active : styles.inactive;
}

export default function NavLink({ title, address, isActive, ariaLabel, clickEntity }: Props): JSX.Element {
  const { mapView } = useMap();
  const analytics = useAnalytics();
  const handleClick = useCallback(
    async e => {
      e.preventDefault();
      const simpleClickFact: SimpleClickFact = getSimpleClickFact(clickEntity, HEADER, 'null', 'null', mapView, 'null');
      await analytics.reportFactImmediately<SimpleClickFact>(simpleClickFact);
      window.open(address, '_self');
    },
    [clickEntity, mapView, analytics, address]
  );
  return (
    <Link
      aria-label={ariaLabel}
      aria-current={isActive}
      href={address}
      className={getStyles(isActive)}
      onClick={handleClick}
      prefetch={false}
    >
      {title}
    </Link>
  );
}
