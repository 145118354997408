import { IntlMessageFormat } from 'intl-messageformat';
import type { Locale } from '@/i18n';
import type { Currency } from '@/models/Currency';
import { toPhraseLocale } from '@/i18n';

const EST_TIMEZONE = 'America/New_York';
const USD = 'USD';

// TODO: wrap IntlMessageFormat with a cache layer to avoid the 'new' operator calls
/* eslint-disable-next-line @typescript-eslint/no-explicit-any */
export function formatMessage(translationValue: string, locale: Locale, tokens?: Record<string, any>): string {
  return tokens ? new IntlMessageFormat(translationValue, toPhraseLocale(locale)).format(tokens) : translationValue;
}

export function formatNumber(locale: Locale, value: number): string {
  return new Intl.NumberFormat(toPhraseLocale(locale)).format(value);
}

export function formatDate(locale: Locale, value: Date, options?: Intl.DateTimeFormatOptions): string {
  const phraseLocale = toPhraseLocale(locale);
  if (!options) {
    return new Intl.DateTimeFormat(phraseLocale).format(value);
  }
  let formattedDate: string;
  try {
    formattedDate = new Intl.DateTimeFormat(phraseLocale, options).format(value);
  } catch (e) {
    // if provided with invalid timezone eg. 'Etc/Unknown', fallback to EST timezone
    if (options.timeZone && e.name === 'RangeError') {
      formattedDate = new Intl.DateTimeFormat(phraseLocale, {
        ...options,
        timeZone: EST_TIMEZONE
      }).format(value);
    } else {
      // ignore options
      formattedDate = new Intl.DateTimeFormat(phraseLocale).format(value);
    }
  }

  return formattedDate;
}

export function formatCurrency(
  locale: Locale,
  value: number,
  currency?: Currency | null,
  minimumFractionDigits?: number,
  maximumFractionDigits?: number
): string {
  return new Intl.NumberFormat(toPhraseLocale(locale), {
    style: 'currency',
    currency: currency ?? USD,
    minimumFractionDigits,
    maximumFractionDigits
  }).format(value);
}
