'use client';

import useShoppingCart from '@/hooks/useShoppingCart';
import type { Locale } from '@/i18n';
import { useCallback } from 'react';
import { VENUE_LIMIT_LOGGED_IN_USER, getRemainingVenueCountMessage } from '@/utils/shoppingCartHelper';
import Icon from '@/components/shared/Icons';
import { formatMessage } from '@/utils/translationHelper';

const headerWrapperStyle =
  'pt-[10px] pb-[10px] pl-[20px] pr-[20px] rounded-t-[10px] bg-[#fff] text-[#1A2026] text-[14px] w-full z-[1] animate-[flyoutEaseIn_250ms_ease-in]';

type ShoppingCartHeaderProps = {
  locale: Locale;
  strings: Record<string, string>;
  isLoggedIn: boolean;
};

function getVenueListTitle(
  isLoggedIn: boolean,
  cartSize: number,
  strings: Record<string, string>,
  locale: Locale,
  highlightRecentVenue: boolean
): string {
  let venueListTitle;
  if (cartSize === 0) {
    venueListTitle = strings.empty_cart_header;
  } else if (isLoggedIn) {
    venueListTitle = strings._venue_cart;
  } else {
    venueListTitle = formatMessage(strings._request_quote_from_x_venues, locale, {
      number: cartSize
    });
  }
  // Update the venue List Title when a venue is added to the cart
  if (highlightRecentVenue && cartSize > 0) {
    venueListTitle = strings._venue_added;
  }
  return venueListTitle;
}

function getVenueListSubTitle(
  isLoggedIn: boolean,
  cartSize: number,
  strings: Record<string, string>,
  locale: Locale
): string {
  let venueListSubtitle;
  if (cartSize === 0) {
    if (isLoggedIn) {
      venueListSubtitle = '';
    } else {
      venueListSubtitle = strings.empty_cart_subheader;
    }
  } else if (!isLoggedIn) {
    venueListSubtitle = getRemainingVenueCountMessage(isLoggedIn, cartSize, strings, locale);
  } else if (isLoggedIn && cartSize < VENUE_LIMIT_LOGGED_IN_USER) {
    venueListSubtitle = strings.create_or_add_to_rfp;
  } else {
    venueListSubtitle = strings.list_full_create_or_add_to_rfp;
  }
  return venueListSubtitle;
}

export default function ShoppingCartHeader({ isLoggedIn, strings, locale }: ShoppingCartHeaderProps) {
  const cart = useShoppingCart();
  const { items, highlightRecentVenue, setHighlightRecentVenue, setIsOpen } = cart;
  const cartSize = Object.keys(items).length;

  const close = useCallback(() => {
    setIsOpen(false);
    setHighlightRecentVenue(false);
  }, [setIsOpen, setHighlightRecentVenue]);

  return (
    <div className={headerWrapperStyle}>
      <h4 className="text-[18px]" data-cvent-id="shopping-cart-title">
        {getVenueListTitle(isLoggedIn, cartSize, strings, locale, highlightRecentVenue)}
      </h4>
      {getVenueListSubTitle(isLoggedIn, cartSize, strings, locale)}
      <button
        type="button"
        aria-label={strings._close}
        className="absolute right-[10px] top-[5px] h-[32px] w-[32px] text-[15px] no-underline"
        onClick={close}
      >
        <Icon id="x" className="h-[32px] w-[32px] fill-[rgba(34,40,42,.6)] leading-[1]" />
      </button>
    </div>
  );
}
