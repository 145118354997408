export const rfpStatus = {
  AWAITING_PROPOSAL: 'AWAITING_PROPOSAL',
  SUBMITTED_PROPOSAL: 'SUBMITTED_PROPOSAL',
  TURNED_DOWN: 'TURNED_DOWN',
  NOT_SENT: 'NOT_SENT',
  WITHDRAWN: 'WITHDRAWN',
  DECLINED: 'DECLINED',
  CANCELLED: 'CANCELLED',
  AWARDED: 'AWARDED',
  NO_RESPONSE: 'NO_RESPONSE',
  FORWARDED: 'FORWARDED',
  RECEIPT_CONFIRMED: 'RECEIPT_CONFIRMED'
};
