import Accordion from '@/components/legacy/header/Accordion';
import Checkbox from '@/components/shared/temp/Checkbox';
import { useCallback, useEffect, useState, Dispatch, SetStateAction } from 'react';
import { formatMessage } from '@/utils/translationHelper';
import type { PlannerSettingOptions } from '@/components/legacy/header/Header';
import { TOTAL_NUMBER_OF_SPECIAL_EVENT_VENUE_TYPES } from '@/constants/settingConstants';
import type { VenueTypes } from '@/utils/settingsHelper';
import type { Locale } from '@/i18n';

type Props = {
  plannerSettingOptions: PlannerSettingOptions;
  containerType: string;
  locale: Locale;
  venueTypes: VenueTypes;
  setVenueTypes: Dispatch<SetStateAction<VenueTypes>>;
};

function updateVenueFilter(prev: VenueTypes, isDefault: boolean, { id, checked }): VenueTypes {
  if (checked) {
    if (isDefault) {
      return { ...prev, defaultSelectedVenues: [...prev.defaultSelectedVenues, id] };
    }
    return { ...prev, SEVVenueTypesIncludedInSearch: [...prev.SEVVenueTypesIncludedInSearch, id] };
  }
  if (isDefault) {
    return { ...prev, defaultSelectedVenues: prev.defaultSelectedVenues.filter(item => item !== id) };
  }
  return { ...prev, SEVVenueTypesIncludedInSearch: prev.SEVVenueTypesIncludedInSearch.filter(item => item !== id) };
}

/**
 * @param plannerSettingOptions an object containing locale-specific options for planner settings like venue types and labels
 * @param containerType the type of container to render the planner settings in
 * @param locale the current locale
 * @returns a JSX element representing the planner settings component
 * */

export default function PlannerSettings({
  plannerSettingOptions,
  containerType,
  locale,
  venueTypes,
  setVenueTypes
}: Props): JSX.Element {
  const [selectedElementsText, setSelectedElementsText] = useState('');
  const [buttonText, setButtonText] = useState(plannerSettingOptions.selectAll);
  const [isAllSelected, setIsAllSelected] = useState(false);
  const [isAccordionCollapsed, setIsAccordionCollapsed] = useState(true);
  const sevVenueTypes = useCallback(
    () => plannerSettingOptions.sevLabels.map(venue => venue.value),
    [plannerSettingOptions]
  );

  const onVenueTypeSelected = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      const { id, checked } = e.target;
      const isDefault = plannerSettingOptions.defaultVenueLabels.some(venue => venue.value === id);
      setVenueTypes((prev: VenueTypes) => updateVenueFilter(prev, isDefault, { id, checked }));
    },
    [plannerSettingOptions, setVenueTypes]
  );

  useEffect(() => {
    if (venueTypes.SEVVenueTypesIncludedInSearch.length > 0) {
      const selected = formatMessage(plannerSettingOptions.selectedSEVs, locale, {
        selected: venueTypes.SEVVenueTypesIncludedInSearch.length,
        total: TOTAL_NUMBER_OF_SPECIAL_EVENT_VENUE_TYPES
      });
      setSelectedElementsText(selected);
    } else {
      setSelectedElementsText('');
    }

    if (venueTypes.SEVVenueTypesIncludedInSearch.length === sevVenueTypes().length) {
      setButtonText(plannerSettingOptions.deselectAll);
      setIsAllSelected(true);
    } else {
      setButtonText(plannerSettingOptions.selectAll);
    }
  }, [sevVenueTypes, plannerSettingOptions, venueTypes, locale]);

  const onSelectAll = useCallback(() => {
    setButtonText(prev => {
      if (prev === plannerSettingOptions.selectAll) {
        return plannerSettingOptions.deselectAll;
      }
      return plannerSettingOptions.selectAll;
    });
    setIsAllSelected(!isAllSelected);
    setVenueTypes(prev => ({ ...prev, SEVVenueTypesIncludedInSearch: sevVenueTypes() }));
    if (isAllSelected) {
      setVenueTypes(prev => ({ ...prev, SEVVenueTypesIncludedInSearch: [] }));
    }
  }, [isAllSelected, plannerSettingOptions, setVenueTypes, sevVenueTypes]);

  const sevVenues = {
    title: plannerSettingOptions.specialEventVenues,
    contentClassName: `flex flex-col ${containerType === 'popper' ? 'h-40 overflow-y-auto pl-0.5' : ''}`,
    accordionTextClassName: `${containerType === 'popper' ? 'w-56' : ''} pl-1 text-left text-b-md text-neutral-80`,
    content: Object.values(plannerSettingOptions.sevLabels).map(venue => {
      const { plannerSettingLabel, label, value } = venue;
      return (
        <Checkbox
          key={value}
          id={value}
          label={(plannerSettingLabel || label) ?? ''}
          checked={venueTypes.SEVVenueTypesIncludedInSearch.includes(value)}
          className="flex items-center py-1 text-b-md"
          labelClassName="text-neutral-80"
          onChange={onVenueTypeSelected}
        />
      );
    })
  };
  const defaultVenues = plannerSettingOptions.defaultVenueLabels.map(venue => {
    const { plannerSettingLabel, label, value } = venue;
    return (
      <Checkbox
        key={value}
        id={value}
        label={(plannerSettingLabel || label) ?? ''}
        checked={venueTypes.defaultSelectedVenues.includes(value)}
        className="border-b-solid flex items-center border-b border-neutral-40 py-2 pl-0.5 text-b-md"
        labelClassName={`${containerType === 'popper' ? 'w-80' : ''} text-neutral-80`}
        onChange={onVenueTypeSelected}
      />
    );
  });
  const { title, contentClassName, accordionTextClassName, content } = sevVenues;
  return (
    <div className={`${containerType === 'popper' ? 'h-72' : 'h-[calc(100vh_-_15rem)]'} overflow-y-auto`}>
      <div className="border-b-solid border-b border-neutral-40 py-1 text-b-md text-neutral-80">
        {plannerSettingOptions.defaultIncludeSearchLabel}
      </div>
      <div className="border-b-solid relative flex items-center justify-between border-b border-neutral-40 py-4 pl-1">
        <Accordion
          accordionText={title}
          accordionStyles={{
            contentClassName,
            accordionTextClassName,
            accordionIconClassName: 'h-8 w-8 fill-neutral-60'
          }}
          isAccordionCollapsed={isAccordionCollapsed}
          setIsAccordionCollapsed={setIsAccordionCollapsed}
        >
          {content}
        </Accordion>

        <div className="absolute right-2 top-3.5 flex flex-col items-center">
          <span className="text-center text-b-xs text-neutral-80" aria-label={selectedElementsText}>
            {selectedElementsText}
          </span>
          <button onClick={onSelectAll} type="button" aria-label={buttonText}>
            <span className="text-b-xs text-brand-60">{buttonText}</span>
          </button>
        </div>
      </div>
      <div className="text-b-md">{defaultVenues}</div>
    </div>
  );
}
