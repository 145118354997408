import { usePathname, useSearchParams } from 'next/navigation';
import { Suspense, useEffect } from 'react';
import useSpinner from '@/hooks/useSpinner';

function HandleOnCompleteChild() {
  const pathname = usePathname();
  const searchParams = useSearchParams();
  const { setShowSpinner, setShowMapLoader, setShowMapLoaderInMediumView } = useSpinner();
  useEffect(() => {
    setShowSpinner(false);
    setShowMapLoader(false);
    setShowMapLoaderInMediumView(false);
  }, [pathname, searchParams, setShowMapLoader, setShowSpinner, setShowMapLoaderInMediumView]);
  return null;
}

export function HandleOnComplete() {
  return (
    <Suspense>
      <HandleOnCompleteChild />
    </Suspense>
  );
}
