'use client';

import Icon from '@/components/shared/Icons';
import { rfpStatus } from '@/constants/rfpStatus';
import { useMemo } from 'react';

const baseTag = 'rounded-[10px] py-0.5 px-2.5 text-b-xs mb-[5px] inline-flex border-solid border-1';
const baseIcon = 'h-3 w-3';

type StatusTagProps = {
  status: string;
  strings: Record<string, string>;
};

type StatusTagStyles = {
  tagStyle: string;
  tagIcon: string;
  tagType: 'check' | 'x' | 'more-horizontal';
};

function getStyleAndIcon(status: string): StatusTagStyles {
  let tagStyle: string;
  let tagIcon: string;
  let tagType: 'check' | 'x' | 'more-horizontal';
  switch (status) {
    case rfpStatus.SUBMITTED_PROPOSAL:
    case rfpStatus.AWARDED:
      tagStyle = `${baseTag} bg-[#E8F6EC] text-[#0D7B2A] border-green`;
      tagIcon = `${baseIcon} fill-[#0D7B2A]`;
      tagType = 'check';
      break;
    case rfpStatus.TURNED_DOWN:
    case rfpStatus.CANCELLED:
    case rfpStatus.WITHDRAWN:
    case rfpStatus.DECLINED:
      tagStyle = `${baseTag} bg-[#FDEFEF] text-[#B33E3E] border-[#ED6262]`;
      tagIcon = `${baseIcon} fill-[#B33E3E]`;
      tagType = 'x';
      break;
    case rfpStatus.AWAITING_PROPOSAL:
    case rfpStatus.NOT_SENT:
    case rfpStatus.NO_RESPONSE:
    case rfpStatus.FORWARDED:
    case rfpStatus.RECEIPT_CONFIRMED:
    default:
      tagStyle = `${baseTag} bg-[#E9EDEF] text-[#3D4D54] border-[#1A2026]`;
      tagIcon = `${baseIcon} fill-[#3D4D54]`;
      tagType = 'more-horizontal';
      break;
  }
  return {
    tagStyle,
    tagIcon,
    tagType
  };
}

/* Displays the RFP Status for a particular venue */
export default function StatusTag({ status, strings }: StatusTagProps): JSX.Element {
  const statusStyle = useMemo(() => getStyleAndIcon(status), [status]);
  const { tagStyle, tagIcon, tagType } = statusStyle;
  const tagText = strings[status];

  return (
    <div className={tagStyle}>
      <span className="relative top-0.5 pr-[5px]">
        <Icon id={tagType} className={tagIcon} aria-label={tagText} />
      </span>
      <span>{tagText}</span>
    </div>
  );
}
