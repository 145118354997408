'use client';

import { useMemo } from 'react';
import type { ShoppingCartItems } from '@/models/ShoppingCartItems';
import type { Locale } from '@/i18n';
import CartFooterForLoggedInUsers from '@/components/legacy/header/cart/footer/CartFooterForLoggedInUsers';
import CartFooterForGuestUsers from '@/components/legacy/header/cart/footer/CartFooterForGuestUsers';

export type FooterProps = {
  cartItems: ShoppingCartItems;
  strings: Record<string, string>;
  testID?: string;
  locale: Locale;
  isPhxEvtContainerExpOn: boolean;
  isLoggedIn?: boolean;
  styles?: {
    baseStyle: string;
    linkStyle: string;
    buttonStyle: string;
  };
};

export default function ShoppingCartFooter(footerProps: FooterProps): JSX.Element {
  const { isLoggedIn, cartItems } = footerProps;
  const isCartEmpty = Object.keys(cartItems).length === 0;
  const styles = useMemo(() => {
    const buttonBaseStyles = 'mb-[10px] w-full rounded-[10px] px-[30px] py-[10px] text-[16px] font-[400]';
    return {
      baseStyle: 'bg-[#eeeff0] pb-[10px] pl-[20px] pr-[20px] pt-[16px] rounded-b-[10px] text-center text-[#1A2026]',
      linkStyle: isCartEmpty ? 'pointer-events-none cursor-default' : '',
      buttonStyle: isCartEmpty
        ? `${buttonBaseStyles} bg-[#dddee1] text-[#94969f] hover:bg-[#dddee1] hover:text-[#94969f]`
        : `${buttonBaseStyles} bg-[#006AE1] text-[#fff] hover:bg-[#004CD6]`
    };
  }, [isCartEmpty]);
  return isLoggedIn ? (
    <CartFooterForLoggedInUsers {...footerProps} styles={styles} />
  ) : (
    <CartFooterForGuestUsers {...footerProps} styles={styles} />
  );
}
