import { queryStringConstants } from '@/constants/queryConstants';

export type AllVenueTypes =
  | 'BLANK'
  | 'ENTERTAINMENT_VENUE'
  | 'AQUARIUM_ZOO'
  | 'ARENA_STADIUM'
  | 'BANQUET_FACILITY'
  | 'CONFERENCE_CENTER'
  | 'CONVENTION_CENTER'
  | 'CORPORATE_OFFICE_SPACE'
  | 'EDUCATIONAL_FACILITY'
  | 'EXPOSITION_CENTER'
  | 'GALLERY_MUSEUM'
  | 'GOLF_COURSE'
  | 'HOTEL'
  | 'MOVIE_THEATRE'
  | 'RESORT'
  | 'RESTAURANT'
  | 'SPECIAL_EVENT_VENUE'
  | 'LUXURY_HOTEL'
  | 'CVB'
  | 'DMC'
  | 'BOUTIQUE_HOTEL'
  | 'BAR_CLUB'
  | 'INTERNAL_VENUE';

type DefaultUserSettings = {
  includeRestaurantInSearchFlag: boolean;
  includeDMCVenuesInSearchFlag: boolean;
  includeConventionCenterVenuesInSearchFlag: boolean;
  includeCVBVenuesInSearchFlag: boolean;
  SEVVenueTypesIncludedInSearch: string[];
};
export interface ReceivedPlannerSettings extends DefaultUserSettings {
  measurementUnitTypeId: number;
}
export interface PlannerSettings extends DefaultUserSettings {
  measurementUnitType: string;
}
export interface VenueTypes extends PlannerSettings {
  defaultSelectedVenues: string[];
}

type PersistUserConfigsPayload = {
  hasAccountConfigUpdated: boolean;
  hasUserSettingsUpdated: boolean;
  plannerSettings: PlannerSettings;
};

function buildPlannerSettingsPayload(userSettings: {
  measurementUnitType: string;
  includeRestaurantInSearchFlag: boolean;
  includeDMCVenuesInSearchFlag: boolean;
  includeConventionCenterVenuesInSearchFlag: boolean;
  includeCVBVenuesInSearchFlag: boolean;
  SEVVenueTypesIncludedInSearch: string[];
}): PlannerSettings {
  const {
    measurementUnitType,
    includeRestaurantInSearchFlag,
    includeDMCVenuesInSearchFlag,
    includeConventionCenterVenuesInSearchFlag,
    includeCVBVenuesInSearchFlag,
    SEVVenueTypesIncludedInSearch
  } = userSettings;
  return {
    measurementUnitType,
    includeRestaurantInSearchFlag,
    includeDMCVenuesInSearchFlag,
    includeConventionCenterVenuesInSearchFlag,
    includeCVBVenuesInSearchFlag,
    SEVVenueTypesIncludedInSearch
  };
}

export function getPayloadForPersistingUserConfigs(
  plannerSettings: PlannerSettings,
  updatedMeasurementUnitType: string,
  updatedVenues: VenueTypes
): PersistUserConfigsPayload {
  let hasAccountConfigUpdated = false;
  let hasUserSettingsUpdated = false;
  const { SEVVenueTypesIncludedInSearch, defaultSelectedVenues } = updatedVenues;
  if (plannerSettings.measurementUnitType !== updatedMeasurementUnitType) {
    hasAccountConfigUpdated = true;
  }
  let sevVenueTypeSelectionChanged =
    plannerSettings.SEVVenueTypesIncludedInSearch.length !== SEVVenueTypesIncludedInSearch.length;
  if (!sevVenueTypeSelectionChanged) {
    sevVenueTypeSelectionChanged = SEVVenueTypesIncludedInSearch.some(
      (selectedSEVValue: string) => !plannerSettings.SEVVenueTypesIncludedInSearch.includes(selectedSEVValue)
    );
  }
  if (
    plannerSettings.includeRestaurantInSearchFlag !== defaultSelectedVenues.includes('RESTAURANT') ||
    plannerSettings.includeDMCVenuesInSearchFlag !== defaultSelectedVenues.includes('DMC') ||
    plannerSettings.includeConventionCenterVenuesInSearchFlag !== defaultSelectedVenues.includes('CONVENTION_CENTER') ||
    plannerSettings.includeCVBVenuesInSearchFlag !== defaultSelectedVenues.includes('CVB') ||
    sevVenueTypeSelectionChanged
  ) {
    hasUserSettingsUpdated = true;
  }
  return {
    hasAccountConfigUpdated,
    hasUserSettingsUpdated,
    plannerSettings: buildPlannerSettingsPayload({
      measurementUnitType: updatedMeasurementUnitType,
      includeRestaurantInSearchFlag: defaultSelectedVenues.includes('RESTAURANT'),
      includeDMCVenuesInSearchFlag: defaultSelectedVenues.includes('DMC'),
      includeConventionCenterVenuesInSearchFlag: defaultSelectedVenues.includes('CONVENTION_CENTER'),
      includeCVBVenuesInSearchFlag: defaultSelectedVenues.includes('CVB'),
      SEVVenueTypesIncludedInSearch
    })
  };
}

export function mapMeasurementUnitType(measurementUnit: number): string {
  return measurementUnit === 3 ? queryStringConstants.METRIC : queryStringConstants.IMPERIAL;
}

function getDefaultSelectedVenues(userConfigs: ReceivedPlannerSettings): string[] {
  const defaultSelectedVenues: string[] = [];
  if (userConfigs.includeRestaurantInSearchFlag) {
    defaultSelectedVenues.push('RESTAURANT');
  }
  if (userConfigs.includeDMCVenuesInSearchFlag) {
    defaultSelectedVenues.push('DMC');
  }
  if (userConfigs.includeConventionCenterVenuesInSearchFlag) {
    defaultSelectedVenues.push('CONVENTION_CENTER');
  }
  if (userConfigs.includeCVBVenuesInSearchFlag) {
    defaultSelectedVenues.push('CVB');
  }
  return defaultSelectedVenues;
}

export function transformPlannerSettings(userConfigs: ReceivedPlannerSettings): PlannerSettings {
  const {
    includeConventionCenterVenuesInSearchFlag,
    includeCVBVenuesInSearchFlag,
    includeDMCVenuesInSearchFlag,
    includeRestaurantInSearchFlag,
    SEVVenueTypesIncludedInSearch,
    measurementUnitTypeId
  } = userConfigs;
  return {
    includeConventionCenterVenuesInSearchFlag,
    includeCVBVenuesInSearchFlag,
    includeDMCVenuesInSearchFlag,
    includeRestaurantInSearchFlag,
    SEVVenueTypesIncludedInSearch,
    measurementUnitType: mapMeasurementUnitType(measurementUnitTypeId)
  };
}

export function transformVenueTypes(userConfigs: ReceivedPlannerSettings): VenueTypes {
  const {
    includeConventionCenterVenuesInSearchFlag,
    includeCVBVenuesInSearchFlag,
    includeDMCVenuesInSearchFlag,
    includeRestaurantInSearchFlag,
    SEVVenueTypesIncludedInSearch,
    measurementUnitTypeId
  } = userConfigs;

  const defaultSelectedVenues = getDefaultSelectedVenues(userConfigs);
  return {
    includeConventionCenterVenuesInSearchFlag,
    includeCVBVenuesInSearchFlag,
    includeDMCVenuesInSearchFlag,
    includeRestaurantInSearchFlag,
    SEVVenueTypesIncludedInSearch,
    measurementUnitType: mapMeasurementUnitType(measurementUnitTypeId),
    defaultSelectedVenues
  };
}
