import { useSearchParams } from 'next/navigation';
import useMap from '@/hooks/useMap';
import useAnalytics from '@/hooks/useAnalytics';
import { queryStringConstants } from '@/constants/queryConstants';
import { buildBackUrl } from '@/utils/shoppingCartHelper';
import { useCallback, useEffect, useState } from 'react';
import { SubmitRequestFact } from '@/analytics/models/cventAnalytics/SubmitRequestFact';
import { getSubmitRequestFact } from '@/analytics/helpers/cventAnalytics/getSubmitRequestFact';
import { FooterProps } from '@/components/legacy/header/cart/footer/ShoppingCartFooter';

export default function CartFooterForGuestUsers(footerProps: FooterProps): JSX.Element {
  const { cartItems, strings, locale, styles, testID } = footerProps;
  const searchParams = useSearchParams();
  const { mapView } = useMap();
  const analytics = useAnalytics();
  const filter = searchParams?.get(queryStringConstants.FILTER) ?? '';
  const measurementUnit = searchParams?.get(queryStringConstants.MEASUREMENT_UNIT) ?? 'IMPERIAL';
  const backUrl = buildBackUrl();
  const [guestAppVenueSummaryPageUrl, setGuestAppVenueSummaryPageUrl] = useState<string | undefined>();

  const handleClick = useCallback(
    async e => {
      e.preventDefault();
      const submitRequestFact: SubmitRequestFact = getSubmitRequestFact(mapView, cartItems);
      await analytics.reportFactImmediately<SubmitRequestFact>(submitRequestFact);
      window.open(guestAppVenueSummaryPageUrl, '_self');
    },
    [mapView, cartItems, analytics, guestAppVenueSummaryPageUrl]
  );

  useEffect(() => {
    const fetchGuestUrl = async () => {
      const result = await fetch('/venues/api/cart/url', {
        method: 'POST',
        body: JSON.stringify({ type: 'guest', filter, measurementUnit, locale, cartVenues: cartItems, backUrl })
      });
      if (result.ok) {
        const url = await result.json();
        setGuestAppVenueSummaryPageUrl(url);
      }
    };
    fetchGuestUrl();
  }, [locale, cartItems, filter, measurementUnit, backUrl]);

  return (
    <div className={styles?.baseStyle}>
      <a href={guestAppVenueSummaryPageUrl} className={styles?.linkStyle} onClick={handleClick}>
        <div className={styles?.buttonStyle} data-cvent-id={`${testID}-submit-request`}>
          {strings._experiment_submit_request}
        </div>
      </a>
      <p>{strings._tell_us_about_event}</p>
      <p>{strings._venues_will_talk_to_you}</p>
    </div>
  );
}
