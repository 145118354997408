'use client';

import { useCallback, useState, useRef, useEffect } from 'react';
import dynamic from 'next/dynamic';
import Icon from '@/components/shared/Icons';
import { usePathname, useSearchParams } from 'next/navigation';
import { useRouter } from '@/lib/router-events/patch-router/router';
import { prepareNextPath, DEFAULT_MEASUREMENT_UNIT, prepareUrl } from '@/utils/urlHelper';
import { queryStringConstants } from '@/constants/queryConstants';
import Button from '@/components/shared/button/Button';
import SettingsContent from '@/components/legacy/header/settings/SettingsContent';
import { useLogSuppressor } from '@/hooks/useLogSuppressor';
import type { PlannerSettingOptions } from '@/components/legacy/header/Header';
import { type Locale } from '@/i18n';
import {
  getPayloadForPersistingUserConfigs,
  mapMeasurementUnitType,
  transformPlannerSettings,
  transformVenueTypes,
  type PlannerSettings,
  type VenueTypes
} from '@/utils/settingsHelper';
import { PLANNER_SETTINGS_DEFAULT, VENUE_TYPE_DEFAULTS } from '@/constants/settingConstants';
import useAnalytics from '@/hooks/useAnalytics';
import useMap from '@/hooks/useMap';
import { HEADER } from '@/utils/analyticsClientHelper';
import { getSimpleClickFact } from '@/analytics/helpers/cventAnalytics/getSimpleClickFact';
import type { SimpleClickFact } from '@/analytics/models/cventAnalytics/SimpleClickFact';

const Popper = dynamic(() => import('@/components/shared/popper/Popper'), { ssr: false });
const Modal = dynamic(() => import('@/components/shared/modal'), { ssr: false });

type Props = {
  title: string;
  localeStrings: Record<string, string>;
  plannerSettingOptions: PlannerSettingOptions;
  isLoggedIn: boolean;
  locale: Locale;
  basePath: string;
  disableHeaderPopperDismiss?: () => void;
};

/**
 * Renders a settings container component with options for measurement unit and locale.
 * @param title - The title of the settings container.
 * @param cancelText - The text for the cancel button.
 * @param localeStrings - An object containing locale-specific strings.
 * @param plannerSettingOptions - An object containing locale-specific options for planner settings.
 * @param isLoggedIn - A boolean indicating whether the user is logged in.
 * @param locale - The current locale.
 * @param basePath - The base path for the URL.
 * @returns A JSX element representing the settings container component.
 */
export default function SettingsContainer({
  title,
  localeStrings,
  plannerSettingOptions,
  isLoggedIn,
  locale,
  basePath,
  disableHeaderPopperDismiss
}: Props): JSX.Element {
  const router = useRouter();
  const triggerRef = useRef<HTMLButtonElement>(null);
  const pathname = usePathname();
  const searchParams = useSearchParams();
  const selectedMeasurementUnit = searchParams?.get(queryStringConstants.MEASUREMENT_UNIT) ?? DEFAULT_MEASUREMENT_UNIT;
  const [measurementUnit, setMeasurementUnit] = useState(selectedMeasurementUnit);
  const [selectedLocale, setSelectedLocale] = useState(locale);
  const [isPopperOpen, setIsPopperOpen] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [plannerSettings, setPlannerSettings] = useState<PlannerSettings>(PLANNER_SETTINGS_DEFAULT);
  const [venueTypes, setVenueTypes] = useState<VenueTypes>(VENUE_TYPE_DEFAULTS);
  const { mapView } = useMap();
  const analytics = useAnalytics();
  const triggerSimpleClickFact = useCallback(
    (clickEntity: string) => {
      const simpleClickFact: SimpleClickFact = getSimpleClickFact(clickEntity, HEADER, 'null', 'null', mapView, 'null');
      analytics.reportFact<SimpleClickFact>(simpleClickFact);
    },
    [mapView, analytics]
  );

  useEffect(() => {
    async function getUserConfigs() {
      const result = await fetch('/venues/api/planner-settings');
      if (result.ok) {
        const configs = await result.json();
        setPlannerSettings(transformPlannerSettings(configs));
        setMeasurementUnit(mapMeasurementUnitType(configs.measurementUnitTypeId));
        setVenueTypes(transformVenueTypes(configs));
      }
    }
    if ((isModalOpen || isPopperOpen) && isLoggedIn) {
      getUserConfigs();
    }
  }, [isLoggedIn, isModalOpen, isPopperOpen]);

  const toggleModal = useCallback(() => {
    if (!isModalOpen) {
      triggerSimpleClickFact('settings');
    }
    setIsModalOpen(!isModalOpen);
    if (disableHeaderPopperDismiss != null) {
      disableHeaderPopperDismiss();
    }
  }, [isModalOpen, disableHeaderPopperDismiss, triggerSimpleClickFact]);

  const togglePopper = useCallback(() => {
    if (!isPopperOpen) {
      triggerSimpleClickFact('settings');
    }
    setIsPopperOpen(!isPopperOpen);
  }, [isPopperOpen, triggerSimpleClickFact]);

  const closeModalAndPopper = useCallback(() => {
    if (isPopperOpen) {
      togglePopper();
    }
    if (isModalOpen) {
      toggleModal();
    }
  }, [isPopperOpen, isModalOpen, togglePopper, toggleModal]);

  const cancelHandler = useCallback(() => {
    triggerSimpleClickFact('cancel_settings');
    closeModalAndPopper();
    if (disableHeaderPopperDismiss != null) {
      disableHeaderPopperDismiss();
    }
  }, [closeModalAndPopper, disableHeaderPopperDismiss, triggerSimpleClickFact]);

  const measurementUnitOptions = {
    imperial: localeStrings.imperial,
    metric: localeStrings.metric
  };

  const updateURLWithMeasurementUnit = useCallback(() => {
    const updatedPath = prepareNextPath(basePath, pathname, selectedLocale);
    router.push(
      prepareUrl(updatedPath, searchParams, {
        key: queryStringConstants.MEASUREMENT_UNIT,
        value: measurementUnit
      })
    );
  }, [basePath, measurementUnit, pathname, router, searchParams, selectedLocale]);

  const getFilteredSettingsForAnalytics = useCallback(() => {
    const selectedSettings = Object.entries(plannerSettings)
      .filter(([key]) => key && key !== 'SEVVenueTypesIncludedInSearch' && key !== 'measurementUnitType')
      .map(([key]) => key);
    const settings = [...selectedSettings, ...plannerSettings.SEVVenueTypesIncludedInSearch];
    return settings.map(value => `{${value}}`).join(' ');
  }, [plannerSettings]);

  const handleApply = useCallback(() => {
    triggerSimpleClickFact('apply-settings');
    async function updatePlannerSettings() {
      const payload = getPayloadForPersistingUserConfigs(plannerSettings, measurementUnit, venueTypes);
      await fetch('/venues/api/planner-settings', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(payload)
      });
    }
    if (isLoggedIn) {
      const selectedSettings = getFilteredSettingsForAnalytics();
      triggerSimpleClickFact(`Settings Filters Selected: ${selectedSettings}`);
      updatePlannerSettings();
    }

    // Convert filter field values having ranges to new values if there is a change in measurement unit
    const filter = searchParams?.get(queryStringConstants.FILTER);
    fetch('/venues/api/filter/convert-range', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({
        filter,
        measurementUnit,
        selectedMeasurementUnit,
        venueTypes
      })
    })
      .then(response => {
        if (response.ok) {
          response.json().then(data => {
            const params = data.encoded
              ? [
                  { key: queryStringConstants.FILTER, value: data.encoded },
                  { key: queryStringConstants.MEASUREMENT_UNIT, value: measurementUnit }
                ]
              : [{ key: queryStringConstants.MEASUREMENT_UNIT, value: measurementUnit }];
            const updatedPath = prepareNextPath(basePath, pathname, selectedLocale);
            router.push(prepareUrl(updatedPath, searchParams, ...params));
          });
        }
      })
      .catch(_e => {
        updateURLWithMeasurementUnit();
      });
    closeModalAndPopper();
  }, [
    triggerSimpleClickFact,
    isLoggedIn,
    searchParams,
    measurementUnit,
    selectedMeasurementUnit,
    venueTypes,
    closeModalAndPopper,
    plannerSettings,
    getFilteredSettingsForAnalytics,
    basePath,
    pathname,
    selectedLocale,
    router,
    updateURLWithMeasurementUnit
  ]);

  /** Suppress warning from Carina Modal,
   * Carina Modal is using defaultProps and the support for default props will be removed
   * from function components in future release
   */
  useLogSuppressor(/defaultProps/);
  return (
    <>
      <div className="lg:hidden">
        <button type="button" onClick={toggleModal} aria-label={title}>
          {title}
        </button>
        {isModalOpen && (
          <Modal
            isOpen={isModalOpen}
            onDismiss={toggleModal}
            format="fullscreen"
            title={title}
            zIndex={30}
            aria-label={title}
            portal
          >
            <SettingsContent
              handleApply={handleApply}
              isLoggedIn={isLoggedIn}
              localeStrings={localeStrings}
              plannerSettingOptions={plannerSettingOptions}
              title={title}
              titleClass="mb-4 text-d-xs text-background bg-brand-60 text-center p-4"
              containerClass="flex flex-col rounded-lg bg-background shadow-4 h-full"
              measurementUnitOptions={measurementUnitOptions}
              measurementUnit={measurementUnit}
              setMeasurementUnit={setMeasurementUnit}
              venueTypes={venueTypes}
              setVenueTypes={setVenueTypes}
              locale={locale}
              selectedLocale={selectedLocale}
              setSelectedLocale={setSelectedLocale}
              cancelHandler={cancelHandler}
              containerType="modal"
            />
            <div className="absolute right-0 top-0 p-2">
              <Button
                type="button"
                onClick={toggleModal}
                aria-label={localeStrings.close}
                icon={<Icon id="x" className="w-8 fill-background" />}
              />
            </div>
          </Modal>
        )}
      </div>
      {/* eslint-disable-next-line jsx-a11y/click-events-have-key-events,jsx-a11y/no-static-element-interactions */}
      <div
        className="hidden lg:block "
        onKeyDown={event => {
          if (event.key === 'Escape') {
            setIsPopperOpen(false);
          }
        }}
      >
        <button type="button" onClick={togglePopper} ref={triggerRef}>
          <Icon id="settings" className="hidden h-6 w-6 fill-[#0050AC] xl:block" title={title} />
          <span className="block xl:hidden">{title}</span>
        </button>
        {isPopperOpen && (
          <Popper
            triggerRef={triggerRef}
            isOpen={isPopperOpen}
            arrowSize="medium"
            className="z-30"
            onDismiss={togglePopper}
            preventOverflow
          >
            <SettingsContent
              handleApply={handleApply}
              isLoggedIn={isLoggedIn}
              localeStrings={localeStrings}
              plannerSettingOptions={plannerSettingOptions}
              title={title}
              titleClass="mb-4 text-d-xs text-neutral-90"
              containerClass="max-h-[500px] w-[400px] rounded-lg bg-background p-4 shadow-4"
              measurementUnitOptions={measurementUnitOptions}
              measurementUnit={measurementUnit}
              setMeasurementUnit={setMeasurementUnit}
              venueTypes={venueTypes}
              setVenueTypes={setVenueTypes}
              locale={locale}
              selectedLocale={selectedLocale}
              setSelectedLocale={setSelectedLocale}
              cancelHandler={cancelHandler}
              containerType="popper"
            />
          </Popper>
        )}
      </div>
    </>
  );
}
