'use client';

type VenueLocationProps = {
  city?: string;
  stateCode: string;
  countryCode?: string;
  dataCventId: string;
};

export default function VenueLocation({ city, stateCode, countryCode, dataCventId }: VenueLocationProps): JSX.Element {
  const hasValidStateData = stateCode !== '';
  const shouldDisplayState = countryCode === 'US' || countryCode === 'CA';
  const delimiter = city !== '' ? ', ' : '';

  return (
    <span data-cvent-id={`${dataCventId}-span-addressAllWrapper`}>
      <span itemProp="addressLocality" data-cvent-id={`${dataCventId}-span-addressAllWrapper-span-addressLocality`}>
        {city}
      </span>
      {hasValidStateData && shouldDisplayState ? (
        <span itemProp="addressRegion" data-cvent-id={`${dataCventId}-span-addressAllWrapper-span-addressRegion`}>
          {`${delimiter} ${stateCode.toUpperCase()}`}
        </span>
      ) : null}
    </span>
  );
}
