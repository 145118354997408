import type { Locale } from '@/i18n';
import { formatMessage } from '@/utils/translationHelper';

export const VENUE_LIMIT_GUEST_USER = 10;
export const VENUE_LIMIT_LOGGED_IN_USER = 100;
export const MANAGE_VENUES_URL = '/subscribers/RFP/Manage/Default.aspx?page=4715DBF3-ABF0-4881-9B8C-C1B0831ACFCE';
// Returns the venue count message for flyout header
export function getRemainingVenueCountMessage(
  isLoggedIn: boolean,
  cartSize: number,
  dictionary: Record<string, string>,
  locale: Locale
): string {
  if (isLoggedIn) {
    return '';
  }
  const venueLimit = VENUE_LIMIT_GUEST_USER;
  let remainingVenueCountMessage: string;
  if (cartSize < venueLimit) {
    remainingVenueCountMessage = formatMessage(dictionary._shopping_cart_title_description, locale, {
      number: venueLimit - cartSize
    });
  } else {
    remainingVenueCountMessage = dictionary._shopping_cart_is_full_message;
  }
  return remainingVenueCountMessage;
}

// Returns the current App URL in encoded state
export function buildBackUrl(): string {
  if (window && window.location && window.location.href) {
    return encodeURIComponent(window.location.href);
  }
  return '';
}

export function getVenueLimit(isLoggedIn: boolean): number {
  return isLoggedIn ? VENUE_LIMIT_LOGGED_IN_USER : VENUE_LIMIT_GUEST_USER;
}

export function getRFPListStrings(strings: Record<string, string>): Record<string, string> {
  return {
    // Rfp Status
    awaiting_response: strings.awaiting_response,
    proposal_received: strings.proposal_received,
    rfp_turned_down: strings.rfp_turned_down,
    rfp_not_sent: strings.rfp_not_sent,
    rfp_withdrawn: strings.rfp_withdrawn,
    rfp_declined: strings.rfp_declined,
    rfp_cancelled: strings.rfp_cancelled,
    rfp_awarded: strings.rfp_awarded,
    rfp_no_response: strings.rfp_no_response,
    rfp_forwarded: strings.rfp_forwarded,
    rfp_receipt_confirmed: strings.rfp_receipt_confirmed,

    // Venue Types
    _venue_type_aquarium_zoo: strings._venue_type_aquarium_zoo,
    _venue_type_arena_stadium: strings._venue_type_arena_stadium,
    _venue_type_banquet_facility: strings._venue_type_banquet_facility,
    _venue_type_bar_club: strings._venue_type_bar_club,
    _venue_type_boutique_hotel: strings._venue_type_boutique_hotel,
    _venue_type_category_convention_center: strings._venue_type_category_convention_center,
    _venue_type_category_cvb: strings._venue_type_category_cvb,
    _venue_type_category_hotels: strings._venue_type_category_hotels,
    _venue_type_category_restaurant: strings._venue_type_category_restaurant,
    _venue_type_category_special_event_venues: strings._venue_type_category_special_event_venues,
    _venue_type_conference_center: strings._venue_type_conference_center,
    _venue_type_convention_center: strings._venue_type_convention_center,
    _venue_type_corporate_office_space: strings._venue_type_corporate_office_space,
    _venue_type_cvb: strings._venue_type_cvb,
    _venue_type_dmc: strings._venue_type_dmc,
    _venue_type_educational_facility: strings._venue_type_educational_facility,
    _venue_type_entertainment_venue: strings._venue_type_entertainment_venue,
    _venue_type_exposition_center: strings._venue_type_exposition_center,
    _venue_type_gallery_museum: strings._venue_type_gallery_museum,
    _venue_type_golf_course: strings._venue_type_golf_course,
    _venue_type_hotel: strings._venue_type_hotel,
    _venue_type_internal_venue: strings._venue_type_internal_venue,
    _venue_type_luxury_hotel: strings._venue_type_luxury_hotel,
    _venue_type_movie_theatre: strings._venue_type_movie_theatre,
    _venue_type_resort: strings._venue_type_resort,
    _venue_type_restaurant: strings._venue_type_restaurant,
    _venue_type_special_event_venue: strings._venue_type_special_event_venue,
    _venue_type_the_luxury_collection: strings._venue_type_the_luxury_collection,

    // Others
    venue_already_received_rfp: strings.venue_already_received_rfp,
    view_all_venues: strings.view_all_venues,
    rfp_sent_on: strings.rfp_sent_on,
    csn_search_image_fallbackText: strings.csn_search_image_fallbackText
  };
}

export function getStatusTagStrings(strings: Record<string, string>): Record<string, string> {
  return {
    AWAITING_PROPOSAL: strings.awaiting_response,
    SUBMITTED_PROPOSAL: strings.proposal_received,
    TURNED_DOWN: strings.rfp_turned_down,
    NOT_SENT: strings.rfp_not_sent,
    WITHDRAWN: strings.rfp_withdrawn,
    DECLINED: strings.rfp_declined,
    CANCELLED: strings.rfp_cancelled,
    AWARDED: strings.rfp_awarded,
    NO_RESPONSE: strings.rfp_no_response,
    FORWARDED: strings.rfp_forwarded,
    RECEIPT_CONFIRMED: strings.rfp_receipt_confirmed
  };
}
