import { useCallback } from 'react';

/**
 * Suppress warning error messages that are propagating from external dependencies
 * Do not use this for component created/managed withing mono app only
 * Should be used only in case external libraries after team discussion
 */
export const useLogSuppressor = (regex: RegExp) => {
  const { error } = console;
  // eslint-disable-next-line no-console
  console.error = useCallback(
    (...args: string[]) => {
      if (regex.test(args[0])) return;
      error(...args);
    },
    [error]
  );
};
