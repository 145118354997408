'use client';

import { useCallback, useEffect, useLayoutEffect, useRef, useState } from 'react';
import { useRouter } from '@/lib/router-events/patch-router/router';
import { CVENT_NOTIFICATION_COOKIE_NAME } from '@/constants/cookieConstants';
import Icon from '@/components/shared/Icons';
import dynamic from 'next/dynamic';
import { useBreakpoints } from '@/hooks/useBreakpoints';
import Button from '@/components/shared/button/Button';

const Modal = dynamic(() => import('@/components/shared/modal'));
type Props = {
  notificationAcceptedId?: string;
  strings: {
    message?: string;
    closeButtonAria: string;
    readMoreString: string;
    closeModal: string;
  };
};

const BASE_TTL_DAY = 24 * 60 * 60 * 1000;
const YEAR_COOKIE_TTL = 365 * BASE_TTL_DAY;

function setNotificationCookie(notificationAcceptedId?: string): void {
  const time = new Date();
  time.setTime(time.getTime() + Number(YEAR_COOKIE_TTL));
  if (document) {
    document.cookie = `${CVENT_NOTIFICATION_COOKIE_NAME}=${notificationAcceptedId};expires=${time};path=/`;
  }
}

export default function NotificationBanner({ notificationAcceptedId, strings }: Props) {
  const router = useRouter();
  const [contentHtml, setContentHtml] = useState<string>('');
  const onNotificationBannerClose = useCallback(
    e => {
      e.preventDefault();
      setNotificationCookie(notificationAcceptedId);
      router.refresh();
    },
    [notificationAcceptedId, router]
  );

  // https://github.com/vercel/next.js/issues/49139
  useEffect(() => {
    const div = document.createElement('div');
    div.innerHTML = strings.message || '';
    div.querySelector('a')?.classList.add('cursor-pointer', 'underline', 'hover:text-[#ffd166]');
    setContentHtml(div.innerHTML);
  }, [strings.message]);

  const [modal, changeModal] = useState<boolean>(false);
  const [readMore, setReadMore] = useState<boolean>(false);
  const textRef = useRef<HTMLDivElement>(null);

  useLayoutEffect(() => {
    if (textRef.current) {
      // adding +5 here because for a particular viewport width(when textRef.current.scrollHeight = textRef.current.clientHeight) safari was giving hard error.
      if (textRef.current.scrollHeight > textRef.current.clientHeight + 5 && !readMore) {
        setReadMore(true);
      }
      if (textRef.current.scrollHeight <= textRef.current.clientHeight && readMore) {
        setReadMore(false);
      }
    }
  }, [readMore, textRef.current?.clientHeight, textRef.current?.scrollHeight]);

  const toggleModal = useCallback(() => {
    changeModal(prev => !prev);
  }, [changeModal]);

  const { isMobile } = useBreakpoints();
  if (modal) {
    return isMobile ? (
      <Modal format="fullscreen" data-cvent-id="notification-banner-modal" isOpen={modal} zIndex={50}>
        <div className="flex h-full w-full justify-between bg-[#006ce8]">
          <div
            onClick={onNotificationBannerClose}
            onKeyDown={onNotificationBannerClose}
            role="button"
            tabIndex={0}
            aria-label={strings.closeButtonAria}
            className="absolute right-3 mt-[1rem] flex h-6 w-6"
          >
            <Icon id="x" className="left-1/2 top-1/2 fill-brand-10" />
          </div>
          <div
            className="mr-[10px] overflow-auto px-[1rem] pr-[3rem] pt-[1rem] font-primary text-[.95rem] font-bold leading-[1.6] text-brand-10 antialiased"
            // eslint-disable-next-line react/no-danger
            dangerouslySetInnerHTML={{ __html: contentHtml }}
          />
        </div>
      </Modal>
    ) : (
      <Modal format="s" className="w-full p-6" data-cvent-id="notification-banner-modal" isOpen={modal} zIndex={50}>
        <div className="h-full w-full justify-between">
          <div
            className="mr-[10px] w-full overflow-auto pt-2 font-primary text-b-lg leading-[1.6] antialiased"
            // eslint-disable-next-line react/no-danger
            dangerouslySetInnerHTML={{ __html: contentHtml }}
          />
        </div>
        <div className="mt-4 flex justify-end">
          <Button
            appearance="lined"
            text={strings.closeModal}
            aria-label={strings.closeModal}
            onClick={onNotificationBannerClose}
            className="group rounded-xl p-2 px-3 py-1.5 text-b-md/tight"
          />
        </div>
      </Modal>
    );
  }
  return (
    <div className="relative flex h-[4.25rem] w-full items-center justify-center bg-[#006ce8] px-[1rem] py-2 xl:h-[2.75rem]">
      <div className="space-between flex flex-col justify-center sm:flex-row">
        <div
          ref={textRef}
          className="line-clamp-1 pr-[2rem] text-left font-primary text-[.95rem] font-bold leading-[1.6] text-brand-10 antialiased sm:line-clamp-2 md:text-center xl:line-clamp-1"
          // eslint-disable-next-line react/no-danger
          dangerouslySetInnerHTML={{ __html: contentHtml }}
        />
        {readMore && (
          <button
            type="button"
            aria-label={strings.readMoreString}
            className="mb-1 mr-10 inline-block flex h-6 w-20 whitespace-nowrap align-bottom font-primary text-[.95rem] font-bold leading-[1.6] text-brand-10 sm:pt-6 xl:pt-0"
            onClick={toggleModal}
          >
            <u>{strings.readMoreString}</u>
          </button>
        )}
      </div>
      <button
        type="button"
        aria-label={strings.closeButtonAria}
        className="absolute right-0 top-2/4 -translate-y-2/4 py-[0.813rem] pl-[0.688rem] pr-[0.938rem] hover:text-brand-10 hover:no-underline"
        onClick={onNotificationBannerClose}
      >
        <Icon id="x" height={20} width={20} fill="#fff" />
      </button>
    </div>
  );
}
