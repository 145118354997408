'use client';

import Image, { type ImageProps } from 'next/image';
import { useEffect, useMemo, useState } from 'react';
import placeholder from '@/assets/images/placeholder.png';

import { useLogger } from '@/hooks/useLogger';

const S3_PRODUCTION_PATTERN: RegExp = /^https:\/\/s3.amazonaws.com\/downloads.cvent.com\/(.*)$/;
const S3_T2_PATTERN: RegExp = /^https:\/\/s3.amazonaws.com\/staging-downloads.cvent.com\/T2\/(.*)$/;
const CUSTOM_CVENT_PRODUCTION_PATTERN: RegExp = /^https:\/\/custom.cvent.com\/(.*)$/;
const CLOUDFRONT_PRODUCTION_PATTERN: RegExp = /^https:\/\/d3hfxk7rwdcpol.cloudfront.net\/(.*)$/;
const CUSTOM_CVENT_T2_PATTERN: RegExp = /^https:\/\/custom.t2.cvent.com\/(.*)$/;
const CUSTOM_CVENT_SANDBOX_PATTERN: RegExp = /^https:\/\/sandbox-custom.cvent.com\/(.*)$/;

function resolveImageSource(source: string): string {
  if (source) {
    let src = source;
    if (src.match(CUSTOM_CVENT_PRODUCTION_PATTERN)) {
      // Images on custom.cvent.com are actually in lowercase on S3, even though the URLs we get have uppercase
      // letters in them. We must convert to lowercase
      // See https://wiki.cvent.com/display/DEV/CSN+problems+with+s3proxy+changes
      src = src.toLowerCase();
      src = src.replace(CUSTOM_CVENT_PRODUCTION_PATTERN, 'https://images.cvent.com/$1');
    } else if (src.match(CLOUDFRONT_PRODUCTION_PATTERN)) {
      src = src.replace(CLOUDFRONT_PRODUCTION_PATTERN, 'https://images.cvent.com/$1');
    } else if (src.match(CUSTOM_CVENT_T2_PATTERN)) {
      src = src.toLowerCase();
      src = src.replace(CUSTOM_CVENT_T2_PATTERN, 'https://images-lower.cvent.com/T2/$1');
    } else if (src.match(S3_PRODUCTION_PATTERN)) {
      src = src.replace(S3_PRODUCTION_PATTERN, 'https://images.cvent.com/$1');
    } else if (src.match(S3_T2_PATTERN)) {
      src = src.replace(S3_T2_PATTERN, 'https://images-lower.cvent.com/T2/$1');
    } else if (src.match(CUSTOM_CVENT_SANDBOX_PATTERN)) {
      src = src.toLowerCase();
      src = src.replace(CUSTOM_CVENT_SANDBOX_PATTERN, 'https://images-int.cvent.com/$1');
    }
    return src;
  }
  return placeholder.src;
}

/*
  Wrapper over the next's image component to take care of broken image links
 */
export default function ImageComponent({
  imageProps,
  onClick
}: {
  imageProps: ImageProps;
  onClick?: () => void;
}): JSX.Element {
  const { src } = imageProps;
  const resolvedSource = useMemo(() => resolveImageSource(src?.toString()), [src]);
  const [image, setImage] = useState(resolvedSource);
  const log = useLogger('ImageComponent');
  useEffect(() => {
    setImage(resolvedSource as string);
  }, [resolvedSource]);

  return (
    <Image
      {...imageProps}
      src={image}
      onError={() => {
        log.warn(`broken image for the following URL - ${image}`);
        setImage(placeholder.src.toString());
      }}
      onClick={onClick}
    />
  );
}
