import {
  CSN_ADD_TO_CART_FACT_TYPE,
  CSN_REMOVE_FROM_CART_FACT_TYPE,
  ADD_TO_CART_VERSION,
  getPageUrl,
  SEARCH_RESULTS_PAGE
} from '@/utils/analyticsClientHelper';
import type { ShoppingCartFact } from '@/analytics/models/cventAnalytics/ShoppingCartFact';
import type { ShoppingCartItem } from '@/models/ShoppingCartItem';
import type { ShoppingCartContextType } from '@/providers/ShoppingCartProvider';
import type { MapView } from '@/constants/mapConstants';

// Pulls only analytic specific properties from Shopping Cart Item
function getAnalyticsVenueInfoFromVenue(venue: ShoppingCartItem): string {
  const venueDetails = {
    id: venue.venueId,
    city: venue.city,
    name: venue.name,
    diamondLevel: venue.diamondLevel,
    hasPromotions: venue.hasPromotions ?? false,
    latitude: venue.latitude,
    longitude: venue.longitude,
    listingCode: venue.adId && venue.adId !== 'null' ? venue.adId : undefined,
    listingId: venue.listingId,
    featuredLevel: venue.featuredLevel
  };
  const filteredDetails = Object.fromEntries(
    Object.entries(venueDetails).filter(([, value]) => typeof value !== 'undefined')
  );
  return JSON.stringify(filteredDetails);
}

// Returns the Add To Cart Fact
export function getShoppingCartFact(
  action: 'add' | 'delete',
  venue: ShoppingCartItem,
  cart: ShoppingCartContextType,
  pageNumber: string | null,
  sortType: string | null,
  mapView: MapView,
  activeFilters: string | undefined,
  page?: string
): ShoppingCartFact {
  let shoppingCartRank = Object.keys(cart.items).length + 1;
  if (action === 'delete') {
    shoppingCartRank = cart.getVenuePositionInCart(venue.venueId) + 1;
  }
  const currentPageNumber = pageNumber != null ? Number(pageNumber) : 1;
  const shoppingCartFact: ShoppingCartFact = {
    type: action === 'add' ? CSN_ADD_TO_CART_FACT_TYPE : CSN_REMOVE_FROM_CART_FACT_TYPE,
    active_filters: activeFilters ?? '{}',
    ad_id: venue.adId ?? 'null',
    ad_position: venue.adPosition ?? 'null',
    ad_type: venue.adType ?? 'null',
    auto_added: 'null',
    current_page_number: currentPageNumber,
    microsite_data: 'null',
    meta_data:
      venue.isSTEnabled !== undefined ? JSON.stringify({ social_tables_enabled: venue.isSTEnabled ?? false }) : 'null',
    page: page ?? SEARCH_RESULTS_PAGE,
    promotion_id: venue.promotionId ?? 'null',
    promotion_type: venue.promotionType ?? 'null',
    search_result_rank: venue.searchResultsRank ?? 'null',
    shopping_cart_rank: shoppingCartRank,
    sort_type: sortType ?? 'CVENT_PICKS',
    venue: getAnalyticsVenueInfoFromVenue(venue),
    map_view: mapView,
    search_term: venue.searchTerm ?? 'null',
    page_url: getPageUrl(),
    source: venue.source ?? 'null',
    ver: ADD_TO_CART_VERSION
  };
  return shoppingCartFact;
}
