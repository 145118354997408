/* This is used as client component but directive is not used because of https://github.com/vercel/next.js/discussions/46795 */

import { useCallback } from 'react';
import { supportedLanguages, type Locale } from '@/i18n';
import useAnalytics from '@/hooks/useAnalytics';
import { HEADER } from '@/utils/analyticsClientHelper';
import useMap from '@/hooks/useMap';
import type { SimpleClickFact } from '@/analytics/models/cventAnalytics/SimpleClickFact';
import { getSimpleClickFact } from '@/analytics/helpers/cventAnalytics/getSimpleClickFact';

interface Props {
  title?: string;
  selectedLocale: Locale;
  setSelectedLocale: (locale: Locale) => void;
}

/**
 * Component that renders the locale switcher
 */
export default function LocaleSwitcher({ title, selectedLocale, setSelectedLocale }: Props): JSX.Element {
  const { mapView } = useMap();
  const analytics = useAnalytics();
  const triggerSimpleClickFact = useCallback(
    (clickEntity: string) => {
      const simpleClickFact: SimpleClickFact = getSimpleClickFact(clickEntity, HEADER, 'null', 'null', mapView, 'null');
      analytics.reportFact<SimpleClickFact>(simpleClickFact);
    },
    [mapView, analytics]
  );
  const handleLocaleChange = useCallback(
    e => {
      triggerSimpleClickFact(`language-${e.target.value}`);
      setSelectedLocale(e.target.value);
    },
    [setSelectedLocale, triggerSimpleClickFact]
  );

  return (
    <div className="flex flex-col">
      <span className="text-b-md text-neutral-90">{title}:</span>
      <select
        aria-label={title}
        defaultValue={selectedLocale}
        onChange={handleLocaleChange}
        className="mt-2 h-8 w-full rounded-sm border-1 border-brand-60"
      >
        {supportedLanguages.map(lang => (
          <option key={lang.code} value={lang.code}>
            {lang.name}
          </option>
        ))}
      </select>
    </div>
  );
}
