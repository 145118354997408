import type { Locale } from '@/i18n';
import { formatMessage } from './translationHelper';

const csnNormandyDestinationGuideURI = 'Subscribers/RFP/DestinationGuide/Default.aspx';
const csnNormandyPromotionURI = 'Subscribers/RFP/Default.aspx';
const csnNormandyBaseSubscribersURI = 'subscribers/RFP/Manage/Default.aspx';
const csnNormandyPageIdQueryKey = 'page';
const csnNormandyRfpStubQueryKey = 'rfpstub';
const csnNewGuestDestinationGuideURI = '/meeting-event-planning';
export const DISCOVER_URI = '/discover';
const PHOENIX = 'phoenix';

const csnNormandyRfpSelectionPageId = '4DE9BDBC-15B7-492b-A9A3-226DDCFCEC37';
const csnNormandyRfpSubscriberAndResponsesPageId = '4715DBF3-ABF0-4881-9B8C-C1B0831ACFCE';
const csnNormandyPromotionPageId = '4534DF61-F21C-40E2-A1BF-BE0950547D02';
const csnNormandyDestinationGuidePageId = '9E7F1DBE-05B4-4B6B-87A3-2D22B2AE07F1';

export interface HeaderLinkParameter {
  locale: Locale;
  rfpId?: string | null;
  rfpName?: string | null;
  isPhoenix?: boolean;
  isEventContext?: boolean;
  eventId?: string | null;
  domains: {
    CVENT_GUEST_DOMAIN: string;
    CVENT_APP_DOMAIN: string;
    PHOENIX_APP_DOMAIN: string;
    BASE_PATH: string;
  };
  headerStrings: Record<string, string>;
  luxuryMicrositeName: string;
}

export function getHeaderLinks({
  locale,
  rfpId,
  rfpName,
  isPhoenix,
  isEventContext,
  eventId,
  domains,
  headerStrings,
  luxuryMicrositeName
}: HeaderLinkParameter) {
  const { CVENT_GUEST_DOMAIN, CVENT_APP_DOMAIN, PHOENIX_APP_DOMAIN } = domains;

  let phoenixUrl = rfpId ? `${PHOENIX_APP_DOMAIN}/rfps/${rfpId}/response` : PHOENIX_APP_DOMAIN;
  if (isEventContext) {
    phoenixUrl = `${phoenixUrl}?eventId=${eventId}`;
  }
  let supplierAndResponseUrl = `${CVENT_APP_DOMAIN}/${csnNormandyBaseSubscribersURI}`;
  let normandyLinkText;
  let normandyLinkTextMobile;
  if (rfpName) {
    normandyLinkTextMobile = isPhoenix ? headerStrings._back_to_rfps_venues : headerStrings._back;
  } else {
    normandyLinkTextMobile = isPhoenix ? headerStrings._back_to_all_rfps : headerStrings._back_to_manage_rfps;
  }
  if (rfpId) {
    supplierAndResponseUrl = `${supplierAndResponseUrl}?${csnNormandyPageIdQueryKey}=${csnNormandyRfpSubscriberAndResponsesPageId}&${csnNormandyRfpStubQueryKey}=${rfpId}`;
    normandyLinkText = isPhoenix ? headerStrings._back_to_rfps_venues : headerStrings._back_to_suppliers_and_responses;
  } else {
    supplierAndResponseUrl = `${supplierAndResponseUrl}?${csnNormandyPageIdQueryKey}=${csnNormandyRfpSelectionPageId}`;
    normandyLinkText = isPhoenix ? headerStrings._back_to_all_rfps : headerStrings._back_to_manage_rfps;
  }

  const rfpText = formatMessage(headerStrings._rfp_name, locale, { name: rfpName });
  const rfpNameLink = isPhoenix ? phoenixUrl : supplierAndResponseUrl;

  const plannerPromotionHubUrl = `${CVENT_APP_DOMAIN}/${csnNormandyPromotionURI}${
    isPhoenix ? `?app=${PHOENIX}&` : '?'
  }${csnNormandyPageIdQueryKey}=${csnNormandyPromotionPageId}${rfpId ? `&rfpStub=${rfpId}` : ''}`;

  const plannerDestinationGuideUrl = `${CVENT_APP_DOMAIN}/${csnNormandyDestinationGuideURI}${
    isPhoenix ? `?app=${PHOENIX}&` : '?'
  }${csnNormandyPageIdQueryKey}=${csnNormandyDestinationGuidePageId}${rfpId ? `&rfpId=${rfpId}` : ''}`;

  const luxuryMagazineUrl = `${CVENT_GUEST_DOMAIN}/microsites/${luxuryMicrositeName}${
    isPhoenix ? `?app=${PHOENIX}` : ''
  }${isPhoenix && rfpId ? `&rfpId=${rfpId}` : ''}${!isPhoenix && rfpId ? `?rfpId=${rfpId}` : ''}`;

  const discoverBaseUrl = `${CVENT_GUEST_DOMAIN}/venues${DISCOVER_URI}${isPhoenix ? `?app=${PHOENIX}` : ''}${
    isPhoenix && rfpId ? `&rfpId=${rfpId}` : ''
  }${!isPhoenix && rfpId ? `?rfpId=${rfpId}` : ''}`;

  const newGuestDestinationGuideUrl = `${CVENT_GUEST_DOMAIN}${csnNewGuestDestinationGuideURI}${
    isPhoenix ? `?app=${PHOENIX}` : ''
  }${isPhoenix && rfpId ? `&rfpId=${rfpId}` : ''}${!isPhoenix && rfpId ? `?rfpId=${rfpId}` : ''}`;

  const guestPromotionHubUrl = `${CVENT_GUEST_DOMAIN}/rfp/hotel-promotions/`;
  const guestDestinationGuideUrl = `${CVENT_GUEST_DOMAIN}/rfp/meeting-event-planning.aspx`;
  const homePageUrl = `${CVENT_GUEST_DOMAIN}/venues${isPhoenix ? `?app=${PHOENIX}` : ''}${
    isPhoenix && rfpId ? `&rfpId=${rfpId}` : ''
  }${isEventContext ? `&eventId=${eventId}` : ''}${!isPhoenix && rfpId ? `?rfpId=${rfpId}` : ''}`;

  return {
    homePage: { title: headerStrings._venues, address: homePageUrl },
    phoenixUrl,
    supplierAndResponseUrl,
    normandyLinkText,
    normandyLinkTextMobile,
    rfpText,
    rfpNameLink,
    plannerPromotionHub: { title: headerStrings._promotions, address: plannerPromotionHubUrl },
    plannerDestinationGuide: { title: headerStrings._city_guides, address: plannerDestinationGuideUrl },
    luxuryMagazine: { title: headerStrings._luxury_tab, address: luxuryMagazineUrl },
    discoverBase: { title: headerStrings._discover_brand_page, address: discoverBaseUrl },
    newGuestDestinationGuide: { title: headerStrings._destinations, address: newGuestDestinationGuideUrl },
    guestPromotionHub: { title: headerStrings._promotions, address: guestPromotionHubUrl },
    guestDestinationGuide: { title: headerStrings._city_guides, address: guestDestinationGuideUrl },
    signupLink: {
      title: headerStrings._sign_up,
      address: `${CVENT_APP_DOMAIN}/Subscribers/Signup.aspx?type=planner&locale=${locale}`
    },
    loginLink: { title: headerStrings._log_in, address: `${CVENT_APP_DOMAIN}/Subscribers/Login.aspx?type=planner` }
  };
}
