const env = process.env.NODE_ENV;

export const shouldHandleNormandySession = (normandyEndpoint: string): boolean =>
  (!!normandyEndpoint || env === 'development') && env !== 'test';

let _storage = {
  getItem: () => null,
  setItem: () => null,
  removeItem: () => null
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
} as any;

if (typeof window !== 'undefined') {
  _storage = sessionStorage;
  try {
    localStorage.setItem('hasLocalStorageSpace', 1 as unknown as string);
    localStorage.removeItem('hasLocalStorageSpace');
    _storage = localStorage;
  } catch (e) {
    // no-op
  }
}

export const storage = _storage;
const headers = { 'Content-Type': 'application/json' };

export const redirectToLogin = (normandyEndpoint: string): void => {
  // Set the param to notify the user on the Normandy login that they've been signed out due to inactivity
  const timeoutDate = storage.getItem('timeoutDate');
  let timedOut: boolean | string = false;

  // Per logic, when session times out, the current time will be greater than timeoutDate
  // then add tuoe param to make Login screen show red banner with message - "You’ve been logged out due to inactivity."
  if (timeoutDate && new Date().getTime() >= timeoutDate) timedOut = 'tuoe=mit';

  const redirectUrl = `${normandyEndpoint}/subscribers/login.aspx?returnUrl=`;
  let returnUrl = window.location.href;
  // Normandy wants to see the tuoe param at the end of the returnUrl
  if (timedOut) returnUrl += `${returnUrl.match(/\?/) ? '&' : '?'}${timedOut}`;
  const encodedUrl = encodeURIComponent(returnUrl);
  window.location.href = redirectUrl + encodedUrl;
};

export const logOut = async (normandyEndpoint: string): Promise<void> => {
  try {
    storage.removeItem('timeoutDate');

    // redirect to Logout.aspx
    const redirectUrl = `${normandyEndpoint}/Subscribers/Logout.aspx`;
    window.location.href = redirectUrl;
  } catch (err) {
    throw new Error('[SessionHandler] Unable to log out user');
  }
};

// Make a keep alive request and return the parsed response
export const makeKeepAliveRequest = async (normandyEndpoint: string) => {
  if (typeof window === 'undefined' || !shouldHandleNormandySession(normandyEndpoint)) {
    return false;
  }

  const response = await fetch(`${normandyEndpoint}/Subscribers/WS/SessionAlive.asmx/KeepSessionAlive`, {
    method: 'POST',
    headers,
    credentials: 'include'
  });
  if (!response.json) {
    throw new Error('Service endpoint returned an unusable response');
  }
  const auth = await response.json();
  if (!auth.d || auth.d === null) {
    redirectToLogin(normandyEndpoint);
  }
  const loginTimeout = auth.d.SecuritySettings.LoginTimeout;

  return loginTimeout;
};
