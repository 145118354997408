import { useContext } from 'react';
import { MapContext } from '@/providers/MapProvider';
import type { MapContextType } from '@/providers/MapProvider';
/**
 * A hook for getting access to the map state and actions
 */
export default function useMap(): MapContextType {
  const map = useContext<MapContextType>(MapContext);
  return map;
}
