import {
  CSN_SUBMIT_REQUEST_FACT_TYPE,
  PAGE_TARGET_TYPE_GUEST,
  SEARCH_RESULTS_PAGE,
  getPageUrl
} from '@/utils/analyticsClientHelper';
import type { SubmitRequestFact } from '@/analytics/models/cventAnalytics/SubmitRequestFact';
import type { ShoppingCartItems } from '@/models/ShoppingCartItems';
import type { MapView } from '@/constants/mapConstants';

export function getSubmitRequestFact(mapView: MapView, cartItems: ShoppingCartItems): SubmitRequestFact {
  const venueIds = Object.entries(cartItems).map(([venueId]) => venueId);
  const adIds = Object.entries(cartItems).map(([, venueDetails]) => venueDetails.adId ?? 'null');
  return {
    venues: venueIds,
    ad_ids: adIds,
    map_view: mapView,
    page_url: getPageUrl(),
    page_target_type: PAGE_TARGET_TYPE_GUEST,
    type: CSN_SUBMIT_REQUEST_FACT_TYPE,
    rfp_id: 'null',
    referrer: SEARCH_RESULTS_PAGE,
    venue_count: Object.keys(cartItems).length
  };
}
