'use client';

import Button from '@/components/shared/button/Button';
import MeasurementUnit from '@/components/legacy/header/settings/measurement-unit/MeasurementUnit';
import LocaleSwitcher from '@/components/legacy/header/settings/LocaleSwitcher';
import { type Locale } from '@/i18n';
import PlannerSettings from '@/components/legacy/header/settings/PlannerSettings';
import type { PlannerSettingOptions } from '@/components/legacy/header/Header';
import type { VenueTypes } from '@/utils/settingsHelper';

type Props = {
  handleApply: () => void;
  title: string;
  titleClass: string;
  containerClass: string;
  plannerSettingOptions: PlannerSettingOptions;
  locale: Locale;
  selectedLocale: Locale;
  setSelectedLocale: (locale: Locale) => void;
  measurementUnit: string;
  setMeasurementUnit: (unit: string) => void;
  venueTypes: VenueTypes;
  setVenueTypes: (venueTypes: VenueTypes) => void;
  measurementUnitOptions: {
    imperial: string;
    metric: string;
  };
  isLoggedIn: boolean;
  localeStrings: Record<string, string>;
  cancelHandler: () => void;
  containerType: string;
};

export default function SettingsContent({
  handleApply,
  title,
  titleClass,
  containerClass,
  plannerSettingOptions,
  locale,
  selectedLocale,
  setSelectedLocale,
  measurementUnit,
  setMeasurementUnit,
  venueTypes,
  setVenueTypes,
  measurementUnitOptions,
  isLoggedIn,
  localeStrings,
  cancelHandler,
  containerType
}: Props) {
  return (
    <div className={containerClass}>
      <h3 className={titleClass}>{title}</h3>
      <div className={`${containerType === 'modal' ? 'p-4' : ''}`}>
        <MeasurementUnit
          unitOptions={measurementUnitOptions}
          title={localeStrings.unitLabel}
          measurementUnit={measurementUnit}
          setMeasurementUnit={setMeasurementUnit}
        />

        {!isLoggedIn ? (
          <LocaleSwitcher
            title={localeStrings.languageLabel}
            selectedLocale={selectedLocale}
            setSelectedLocale={setSelectedLocale}
          />
        ) : (
          <PlannerSettings
            plannerSettingOptions={plannerSettingOptions}
            containerType={containerType}
            locale={locale}
            venueTypes={venueTypes}
            setVenueTypes={setVenueTypes}
          />
        )}
      </div>
      <div className={`${containerType === 'modal' ? 'sticky bottom-0 flex-1 items-end p-4' : 'mt-4'} flex gap-4`}>
        <Button
          text={localeStrings.cancel}
          onClick={cancelHandler}
          testID="setting-flyout-cancel-button"
          size="l"
          appearance="ghost"
          className="w-full"
          aria-label={localeStrings.cancel}
        />
        <Button
          text={localeStrings.apply}
          onClick={handleApply}
          testID="setting-flyout-apply-button"
          size="s"
          appearance="filled"
          isBlock
          aria-label={localeStrings.apply}
        />
      </div>
    </div>
  );
}
