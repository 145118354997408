import { useCallback } from 'react';
import Icon from '@/components/shared/Icons';
import type { IconId } from '@/components/shared/Icons';

type Props = {
  children: JSX.Element | JSX.Element[];
  accordionText: string;
  accordionStyles: {
    contentClassName: string;
    accordionIconClassName: string;
    accordionTextClassName: string;
  };
  isAccordionCollapsed?: boolean;
  setIsAccordionCollapsed: (isAccordionCollapsed: boolean) => void;
  iconId?: IconId;
  chevronLeft?: boolean;
  testID?: string;
};

/**
 *
 * @param children content to be displayed when accordion is expanded
 * @param accordionText text displayed on the accordion button
 * @param accordionStyles custom class names for styling the accordion
 * @param isAccordionCollapsed boolean to determine if accordion is collapsed or expanded
 * @param setIsAccordionCollapsed function to set the isAccordionCollapsed state
/**
 * @returns Accordion component
 */
export default function Accordion({
  children,
  accordionText,
  accordionStyles,
  isAccordionCollapsed = true,
  setIsAccordionCollapsed,
  iconId,
  chevronLeft = true,
  testID = 'accordion'
}: Props): JSX.Element {
  const toggleAccordion = useCallback(
    e => {
      e.preventDefault();
      e.stopPropagation();
      setIsAccordionCollapsed(!isAccordionCollapsed);
    },
    [isAccordionCollapsed, setIsAccordionCollapsed]
  );

  return (
    <div className="w-full">
      <button
        data-cvent-id={`${testID}-button`}
        type="button"
        className="flex items-center"
        onClick={toggleAccordion}
        aria-label={accordionText}
      >
        {iconId && <Icon id={iconId as IconId} className="h-4 w-4 fill-neutral-60" />}
        {chevronLeft && (
          <Icon
            id={`${isAccordionCollapsed ? 'chevron-down' : 'chevron-up'}`}
            className={accordionStyles.accordionIconClassName}
          />
        )}
        <span className={accordionStyles.accordionTextClassName}>{accordionText}</span>
        {!chevronLeft && (
          <Icon
            id={`${isAccordionCollapsed ? 'chevron-down' : 'chevron-up'}`}
            className={accordionStyles.accordionIconClassName}
          />
        )}
      </button>
      {!isAccordionCollapsed ? <div className={accordionStyles.contentClassName}>{children}</div> : null}
    </div>
  );
}
