'use client';

import { useCallback, useEffect, useState } from 'react';
import { SessionLocaleStringsType, SessionNotice } from '@/components/userSession/SessionNotice';
import { useKeepAlive, useLastInteraction } from '@/hooks/useUserSession';
import { redirectToLogin, logOut, makeKeepAliveRequest } from './utils';

interface sessionHandlerProps {
  keepAliveInterval: number;
  showNoticeWhenUnder: number;
  normandyEndpoint: string;
  localeStrings: SessionLocaleStringsType;
}

export function SessionHandler({
  keepAliveInterval,
  showNoticeWhenUnder,
  normandyEndpoint,
  localeStrings
}: sessionHandlerProps): JSX.Element {
  const [isSessionPopupOpen, setIsSessionPopupOpen] = useState(false);
  const [getLastInteraction] = useLastInteraction(isSessionPopupOpen);
  const { heartbeat, timeLeft } = useKeepAlive({
    interval: keepAliveInterval * 1000,
    getLastInteraction,
    makeKeepAliveRequest,
    normandyEndpoint
  });
  const logOutHandler = useCallback(() => logOut(normandyEndpoint), [normandyEndpoint]);

  useEffect(() => {
    if (timeLeft < 0) {
      redirectToLogin(normandyEndpoint);
    }
    if (timeLeft <= showNoticeWhenUnder) {
      setIsSessionPopupOpen(true);
    } else {
      setIsSessionPopupOpen(false);
    }
  }, [showNoticeWhenUnder, timeLeft, normandyEndpoint]);

  return (
    <SessionNotice
      isOpen={isSessionPopupOpen}
      onKeepWorking={(): void => {
        try {
          heartbeat(true);
          setIsSessionPopupOpen(false);
        } catch (err) {
          // no-op
        }
      }}
      onLogOut={logOutHandler}
      localeStrings={localeStrings}
    />
  );
}
