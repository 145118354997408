export const TOTAL_NUMBER_OF_SPECIAL_EVENT_VENUE_TYPES = 11;

export const PLANNER_SETTINGS_DEFAULT = {
  includeRestaurantInSearchFlag: false,
  includeDMCVenuesInSearchFlag: false,
  includeConventionCenterVenuesInSearchFlag: false,
  includeCVBVenuesInSearchFlag: false,
  measurementUnitType: 'IMPERIAL',
  SEVVenueTypesIncludedInSearch: []
};

export const VENUE_TYPE_DEFAULTS = {
  includeRestaurantInSearchFlag: false,
  includeDMCVenuesInSearchFlag: false,
  includeConventionCenterVenuesInSearchFlag: false,
  includeCVBVenuesInSearchFlag: false,
  SEVVenueTypesIncludedInSearch: [],
  measurementUnitType: 'IMPERIAL',
  defaultSelectedVenues: []
};
