'use client';

import Icon from '@/components/shared/Icons';

type Props = {
  id: string;
  label: string;
  checked: boolean;
  className?: string;
  labelClassName?: string;
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
};

/**
 *
 * @param id input id for the checkbox
 * @param label text displayed next to the checkbox
 * @param checked checked state for the checkbox e.g. true or false
 * @param className custom class names for styling the checkbox e.g. 'w-6 h-6'
 * @param labelClassName custom class names for styling the label e.g. 'text-neutral-80'
 * @param onChange change handler for the checkbox
 * @returns Checkbox component
 */
export default function Checkbox({ id, label, checked, className, labelClassName, onChange }: Props): JSX.Element {
  return (
    <label htmlFor={id} className={`${className} relative`}>
      <input
        id={id}
        className="mr-2 h-6 w-6 cursor-pointer appearance-none rounded-[0.15em] border border-solid border-neutral-50"
        type="checkbox"
        checked={checked}
        onChange={onChange}
        aria-label={label}
      />
      <div className={`absolute cursor-pointer ${checked ? 'pl-0.5 pt-0.5' : ''} `}>
        {checked ? <Icon id="check" className="h-5 w-5 fill-brand-60" /> : null}
      </div>
      <span className={labelClassName} aria-label={label}>
        {label}
      </span>
    </label>
  );
}
