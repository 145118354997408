'use client';

import Icon from '@/components/shared/Icons';
import { formatMessage } from '@/utils/translationHelper';
import type { Locale } from '@/i18n';

type Props = {
  venueCartLabel: string;
  cartCountLabel: string;
  cartSize: number;
  isOpen: boolean;
  locale: Locale;
};
export default function VenueListText({
  venueCartLabel,
  cartCountLabel,
  cartSize,
  isOpen,
  locale
}: Props): JSX.Element {
  return (
    <div className="flex items-center">
      <Icon
        id={isOpen || cartSize > 0 ? 'building-icon' : 'building-icon-filled'}
        className="relative bottom-0.5 hidden h-[30px] w-[30px] py-[5px] pl-1.5 pr-[3px]
                 text-[21px] lg:inline"
      />
      <span className="p-2 sm:hidden">
        <Icon
          id={isOpen || cartSize > 0 ? 'building-icon' : 'building-icon-filled'}
          className="relative bottom-0.5 inline h-6 w-6"
        />
      </span>
      <span className="hidden pb-[3px] pl-3 pr-0.5 pt-0.5 sm:inline lg:hidden">
        {cartSize === 0 && venueCartLabel}
        {cartSize > 0 &&
          formatMessage(cartCountLabel, locale, {
            number: cartSize
          })}
      </span>
      <span className="relative hidden lg:inline">{venueCartLabel}</span>
    </div>
  );
}
