'use client';

import { useEffect } from 'react';

type BadgeProps = {
  children: number;
  pulse: 'pulse' | 'pulseAndFade' | 'none';
  isCartUpdated: boolean | undefined;
  dataCventId: string;
};

const defaultStyles =
  'bg-[#1daa42] text-white text-center absolute -right-4 -top-2 sm:-top-3.5 h-6 min-w-[25px] shadow-[-1px_1px_5px_0_#1a2026] rounded-2xl';
const badgeContentStyles = 'text-b-md p-1.5 leading-[1.7]';

const pulseStyles = `${defaultStyles}`;
const pulseAndFadeStyles = `${defaultStyles} animate-[badgePulse_500ms_ease-in-out] animate-[badgeFadeIn_500ms_ease-in-out]`;

export default function Badge({ children, pulse, isCartUpdated, dataCventId }: BadgeProps): JSX.Element {
  useEffect(() => {
    if (typeof isCartUpdated === 'undefined') return;
    const badge = document.getElementById('shopping-cart-badge');
    badge?.classList.remove('animate-[badgePulse_500ms_ease-in-out]');
    setTimeout(() => {
      badge?.classList.add('animate-[badgePulse_500ms_ease-in-out]');
    }, 0);
  }, [children, isCartUpdated]);

  return (
    <span id="shopping-cart-badge" className={badgeClassName(pulse)} data-cvent-id={`${dataCventId}-badge`}>
      <span className={badgeContentStyles}>{children}</span>
    </span>
  );
}

function badgeClassName(pulse: string) {
  switch (pulse) {
    case 'pulse':
      return pulseStyles;
    case 'pulseAndFade':
      return pulseAndFadeStyles;
    default:
      return defaultStyles;
  }
}
