import { useContext } from 'react';
import { AnalyticsContext } from '@/providers/AnalyticsProvider';
import type Analytics from '@/analytics/Analytics';

/**
 * A hook to trigger analytics
 */
export default function useAnalytics(): Analytics {
  return useContext<Analytics>(AnalyticsContext);
}
