'use client';

import { useRouter as useRouterOriginal } from 'next/navigation';
import { shouldTriggerStartEvent } from '@/lib/router-events/patch-router/shouldTriggerStartEvent';
import useSpinner from '@/hooks/useSpinner';

export function useRouter(): ReturnType<typeof useRouterOriginal> {
  const router = useRouterOriginal();
  const { setShowSpinner } = useSpinner();
  return {
    ...router,
    push: (href, options) => {
      if (shouldTriggerStartEvent(href)) setShowSpinner(true);
      router.push(href, options);
    },
    replace: (href, options) => {
      if (shouldTriggerStartEvent(href)) setShowSpinner(true);
      router.replace(href, options);
    }
  };
}
