'use client';

import { Link } from '@/lib/router-events/patch-router/link';
import useAnalytics from '@/hooks/useAnalytics';
import { useCallback } from 'react';
import type { SimpleClickFact } from '@/analytics/models/cventAnalytics/SimpleClickFact';
import { getSimpleClickFact } from '@/analytics/helpers/cventAnalytics/getSimpleClickFact';
import { FOOTER } from '@/utils/analyticsClientHelper';
import useMap from '@/hooks/useMap';

interface FooterLinksProps {
  name: string;
  url: string;
  openInCurrentTab?: boolean;
  hideOnMobile?: boolean;
}
// A client component that displays links on the footer
export default function FooterLinks({ name, url, openInCurrentTab, hideOnMobile }: FooterLinksProps): JSX.Element {
  const { mapView } = useMap();
  const analytics = useAnalytics();
  let linkClassName = 'my-1 md:my-0';
  if (hideOnMobile) {
    linkClassName += ' hidden md:block';
  }
  const triggerSimpleClickFact = useCallback(() => {
    const simpleClickFact: SimpleClickFact = getSimpleClickFact(name, FOOTER, 'null', 'null', mapView, 'null');
    analytics.reportFact<SimpleClickFact>(simpleClickFact);
  }, [analytics, mapView, name]);
  return (
    <li className={linkClassName} key={url}>
      <Link href={url} onClick={triggerSimpleClickFact} prefetch={false} target={openInCurrentTab ? '_self' : '_blank'}>
        {name}
      </Link>
    </li>
  );
}
