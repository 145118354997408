'use client';

import Icon from '@/components/shared/Icons';

type Props = {
  label: string;
};

export default function VenueAddedText({ label }: Props): JSX.Element {
  return (
    <div aria-label={label} data-cvent-id="venues-added" className="flex items-center gap-1 p-1" key={label}>
      <Icon id="check" className="h-4 w-4 fill-background" />
      <span className="hidden lg:inline">{label}</span>
    </div>
  );
}
