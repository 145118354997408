export default {
  active: [
    '-mb-px',
    'font-primary',
    'mt-4',
    'border-l',
    'border-r',
    'border-t-[3px]',
    'border-solid',
    'border-x-[#cad0d3]',
    'border-t-[#006AE1]',
    'bg-fill',
    'px-[20px]',
    'py-[10px]',
    'text-[14px]',
    'font-bold',
    'text-[#1A2026]',
    'hover:text-[#006AE1]',
    'hover:no-underline',
    'hover:outline-none',
    'focus:text-[#006AE1]',
    'focus:no-underline',
    'focus:outline-none',
    'active:text-[#006AE1]',
    'active:no-underline',
    'active:outline-none'
  ].join(' '),

  inactive: [
    'font-primary',
    'text-[14px]',
    'text-[#1A2026]',
    'hover:text-[#006AE1]',
    'hover:no-underline',
    'hover:outline-none',
    'focus:text-[#006AE1]',
    'focus:no-underline',
    'focus:outline-none',
    'active:text-[#006AE1]',
    'active:no-underline',
    'active:outline-none',
    'items-end'
  ].join(' '),

  rightLinks: [
    'mr-4',
    'md:mr-0',
    'z-30',
    'absolute right-2',
    'md:relative',
    'text-b-base',
    'font-primary',
    'text-[#0050AC]',
    'fill-[#0050AC]',
    'hover:text-[#0050AC]',
    'hover:no-underline',
    'hover:outline-none',
    'focus:text-[#0050AC]',
    'focus:no-underline',
    'focus:outline-none',
    'active:text-[#0050AC]',
    'active:no-underline',
    'active:outline-none'
  ].join(' '),

  newTag: [
    'relative',
    '-left-4',
    'top-5',
    'self-start',
    'rounded-lg',
    'bg-[#006CE8]',
    'px-1',
    'text-[11px]',
    'text-fill'
  ].join(' '),

  rightContainer: [
    'text-3.5',
    'mr-4',
    'gap-4',
    'flex',
    'items-center',
    'text-brand-60',
    'md:absolute',
    'md:right-0',
    'lg:pt-2'
  ].join(' ')
};
