import { useContext } from 'react';
import { ShoppingCartContext } from '@/providers/ShoppingCartProvider';
import type { ShoppingCartContextType } from '@/providers/ShoppingCartProvider';

/**
 * A hook for getting access to the venue shopping cart and actions
 */
export default function useShoppingCart(): ShoppingCartContextType {
  const cart = useContext<ShoppingCartContextType>(ShoppingCartContext);
  return cart;
}
